.user_profile {
  padding: 80px !important;
  min-height: 500px;
  .header_divisor {
    border-bottom: 1px solid #dee2e6 !important;
    h2 {
      color: #2292cc;
      font-size: 20px;
      font-family: 'Roboto';
    }
    h2:first-child {
      margin-left: 50px;
    }
  }
  .form_content {
    margin-top: 30px !important;
    margin-left: 40px !important;
    p {
      font-size: 18px;
      font-family: 'Roboto';
      color: #878787;
    }
    label {
      font-size: 18px;
      font-family: 'Roboto';
      color: #878787;
    }
    .small {
      font-size: 14px;
    }
    .small_note {
      font-size: 10px;
    }
    .input_file {
      opacity: 0;
      height: 130%;
      width: 130%;
      top: 20;
      left: 0;
      position: absolute;
      cursor: pointer;
    }
    .avatar_img {
      height: 100px;
      width: auto;
    }

    .custom-control {
      z-index: 0;
    }

    .react-datepicker-wrapper {
      width: 100%;
      input {
        padding-left: 15px;
      }
    }
  }
}
