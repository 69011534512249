.create_pack {
  h3 {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0px;
    color: #1e1e1e;
  }
  input {
    color: #2292cc !important;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .custom-select {
    color: #2292cc;
    font-family: 'Roboto';
    font-size: 14px;
    background: #2292cc url('../../assets/icons/arrow-right.svg') right 0.4rem
      center/1.2rem no-repeat;
  }
  .pack_header {
    p {
      font-family: 'Roboto';
      color: #2292cc;
      margin-bottom: 0px;
      font-size: 16px;
      margin-right: 20px;
    }
  }

  .pack_header_bedriven {
    visibility: hidden;
  }
  
  .pack_total_value {
    hr {
      margin-right: 20px;
    }
    .description {
      font-family: 'Roboto';
      letter-spacing: 0px;
      color: #2292cc;
      font-size: 20px;
    }

    .value {
      font-family: 'Roboto';
      font-weight: bold;
      letter-spacing: 0px;
      color: #1e1e1e;
      font-size: 26px;
      margin-right: 20px;
    }
  }
  .details {
    margin-top: 40px;
    h3 {
      margin-bottom: 30px;
    }
    :is(.react-datepicker-wrapper, .react-datepicker__input-container, .react-datepicker__input-container
        input) {
      width: 100% !important;
    }
    input {
      height: 38px;
      color: #2292cc;
      font-family: 'Roboto';
      font-size: 14px;
    }
    .custom-checkbox .custom-control-label::before {
      border-radius: 1.25rem;
    }

    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      border-color: #baddf0;
      background-color: #baddf0;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: #baddf0;
      background-color: #baddf0;
    }
  }
}

.pack_resume {
  h1 {
    color: #1e1e1e;
    font-size: 16px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    color: #878787;
    font-size: 14px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: normal;
    margin-bottom: 2px;
  }

  .footer {
    margin-top: 50px;
  }
}
