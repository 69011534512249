.user_payment {
  min-height: 500px;
  padding: 30px;
  padding-left: 80px !important;
  padding-right: 40px !important;
  padding-top: 90px !important;
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
  h1 {
    font-size: 30px !important;
    color: #1e1e1e;
    font-weight: bold;
  }
  h3 {
    font-size: 16px;
    color: #1e1e1e;
  }
  .wpwl-container.wpwl-container-card.wpwl-clearfix {
    margin-top: 60px;
    .wpwl-button-pay {
      color: #fff;
      background-color: #1a84c6 !important;
      border-color: #1a84c6 !important;
    }
  }
}

//#card_458058776002
//#card_964577485406 > form > div.wpwl-group.wpwl-group-submit.wpwl-clearfix > div > button
