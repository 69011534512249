.navigationbar_component {
  .image-cropper {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
  }

  img {
    height: 100%;
    width: auto;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .btn-light {
    color: #212529;
    background-color: transparent;
    border-color: transparent;
  }
}

.modal_notifications {
  overflow: auto;

  .modal_base {
    width: 50% !important;
    border-radius: 30px;
    padding: 20px 40px 20px 40px !important;
    font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
    margin-top: 20px !important;

    .logo_service {
      height: auto;
      width: 60px;
      margin-right: 15px;
    }

    .seen_notification {
      margin-left: 20px;
      align-items: center;
      text-align: center;
      width: 120px;
    }

    .button_close {
      border-radius: 100% !important;
      border: none;
      background: none;
      outline: none;
      color: black;
      mix-blend-mode: screen;
      position: relative;
      height: 30px;
      width: 30px;
      opacity: 0.5;

      &:before {
        border-radius: 100% !important;
        background: white;
        border-radius: 0.3em;
        content: '';
        mix-blend-mode: color-burn;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    h3 {
      color: #fff;
      font-size: 20px;
      font-weight: normal;
    }

    .brand_col {
      border-left: 1px solid #ffffff8a;
      align-items: center;
      justify-content: center;

      p {
        margin-bottom: 0px;
        color: #fff;
      }
    }

    summary {
      margin-left: 10px;
      margin-bottom: 10px;
      margin-top: 30px;
      font-weight: 600;
    }
  }
}