.text-label {
    margin-left: 5%;
    margin-top: 2%;
    margin-right: 5%;
}
  
.brand-align {
    justify-content: center;
}
  
.margin-price-left {
    margin-left: 5%;
}
  
.margin-price-right {
    margin-right: 5%;
}
  
.icon-size {
    position: absolute;
    left: 90%;
}

.border-image {
    border: solid;
    border-color: #00AEEF4C;
}

.center-align {
    text-align: center;
}

.color-back {
    color: #878787;
}
.can-publish {
    color: red;
    font-weight: bold;
}


    