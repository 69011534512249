.user_subscriptions {
  .chart_container {
    margin: 20px;
    height: 230px;
    width: 230px;
    padding: 10px;
    position: relative;
    align-items: center !important;
    justify-content: center !important;

    canvas {
      height: 200px;
      width: 200px;
    }

    .nodata {
      z-index: 10 !important;
    }

    .data {
      z-index: 100 !important;
    }

    .circle_info {
      top: 80px;
      margin-top: -70px;
      top: 155px;
      text-align: center;
      position: relative;
      cursor: pointer;

      .month {
        font-weight: bold;
      }

      .total {
        font-size: 26px;
        font-weight: bold;
      }
    }
  }

  .product_card_subs {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #1a84c64c;
    border-radius: 30px;
    padding: 30px 30px;
    margin-bottom: 40px;

    .service_logo {
      height: auto;
      width: 100px;
    }

    .text_service {
      letter-spacing: -0.98px;
      text-align: left;
      color: #1e1e1e;
    }

    .text_approved {
      font-size: 17px;
      font-weight: bold;
    }

    .text_product_name {
      color: #1e1e1e;
      text-transform: uppercase;
      margin-bottom: 0px;
    }

    .circle {
      width: 70px;
      height: 70px;
      border-radius: 100%;

      p {
        text-align: center;
        margin-top: 2%;
        font-weight: bold;
        font-size: 13px;
      }

      .badge_alert {
        height: 20px;
        width: 20px;
        border-radius: 50px;
        position: relative;
        top: 0px;
        left: 44px;
        background-color: #ffffff;

        svg {
          position: relative;
          top: -3px;
          right: -4px;
        }
      }
    }

    .circle-service-1 {
      border: 5px solid #00aeef;
    }

    .circle-service-2 {
      border: 5px solid #6dab24;
    }

    .circle-service-5 {
      border: 5px solid #1a84c6;
    }

    .circle-service-3 {
      border: 5px solid #ffca00;
    }

    .circle-service-4 {
      border: 5px solid #d8001a;
    }
  }
}



$topup-Background : #00aeef;

.topup_base {
  background-color: $topup-Background ;

  h2 {
    color: #ffffff;
  }

  .brand_col {
    border: none !important;
  }

  .topup_outer_circle {
    background-color: white;
    border-radius: 200px;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    margin: 0 auto;

    .topup_inner_circle {
      background-color: $topup-Background;
      border-radius: 200px;
      width: 90%;
      height: 90%;
      margin: 0 auto;
      text-align: center;
      display: flex;
      align-items: center;


      .topup_inner_circle_content {
        margin: 0 auto;
        color: white;
        max-width: 90%;
        max-height: 80%;
        overflow-y: hidden;
        font-size: 45px;
        font-weight: bold;
        text-align: left;

        input {

          height: 10%;
          min-height: 30px;
          border: 0px;
          width: 75%;
          color: white;
          background-color: transparent;
          text-align: right;
        }
      }
    }

  }

  .row_button {
    justify-content: center;
    margin-top: 10px;

    .button {
      width: 293px;
      height: 66px;
      background-color: #ffffff;
      border-radius: 33px;
      color: #000;
    }
  }
}

@media screen and (max-width: 575px) {
  .user_subscriptions {
    .chart_container {
      width: 200px;
      height: 200px;
      padding: 0;
      margin: 0;

      .circle_info {

        canvas{

        }
      }
    }
  }
}