.pricing_insert {
  min-height: 90vh;
  .promocode_message {
    margin-left: 10px;
    margin-top: 20px;
    color: #169a63;
    font-weight: bold;
    letter-spacing: 1.5px;
  }

  @media screen and (max-width: 991px) {
    min-height: 750px;
  }
}

.pricing_margin {
  margin-left: 30px !important;
}

.pricing_create {
  min-height: 500px;
  padding-bottom: 50px;
  .react-datepicker-wrapper {
    width: 100%;
    input {
      padding-left: 15px;
      width: 100%;
      height: 38px;
    }
  }
  .services_div {
    background-color: #baddf0 !important;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
  }
}

.princing_modal {
  overflow: auto;
  .princing_modal_container {
    background-color: #fff;
    min-height: 50vh;
    border-radius: 30px;
    .button_close {
      margin-top: 20px;
      margin-right: 20px;
      border: unset;
      background-color: #fff;
    }
  }
}
