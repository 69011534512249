.navbar_component {
  position: sticky;
  top: 45px;

  .image-cropper {
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
  }
}
