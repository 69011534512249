.configurator_main {
  padding: 30px;
  .configurator_tabs {
    cursor: pointer;
    padding-bottom: 10px;
    border-bottom: #eeeeee 1px solid;
    h2 {
      font-size: 16px;
      font-family: 'Roboto';
      color: #878787;
    }
    &.active {
      border-bottom: #2292cc 1px solid;
      h2 {
        font-size: 16px;
        font-family: 'Roboto';
        color: #2292cc;
        font-weight: bold;
      }
    }
  }
}
