.container_modal_contact {
    overflow: auto;

    .modal_base {
        width: 50% !important;
        border-radius: 30px;
        padding: 20px 40px 20px 40px !important;
        font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
        margin-top: 20px !important;

        .button_close {
            border-radius: 100% !important;
            border: none;
            background: none;
            outline: none;
            color: black;
            mix-blend-mode: screen;
            position: relative;
            height: 30px;
            width: 30px;
            opacity: 0.5;
            &:before {
              border-radius: 100% !important;
              background: white;
              border-radius: 0.3em;
              content: '';
              mix-blend-mode: color-burn;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
            }
          }

        .row_button {
            justify-content: center;
            margin-top: 10px;

            .button {
                width: 293px;
                height: 66px;
                background-color: #ffffff;
                border-radius: 33px;
                color: #000;
            }
        }

        .textarea_contact {
            width: 100%;
            height: 150px;
            padding: 12px 20px;
            box-sizing: border-box;
            border: 2px solid #ccc;
            border-radius: 4px;
            background-color: #f8f8f8;
            font-size: 16px;
            resize: none;
        }
    }


}