.cart_modal {
  .card {
    background-color: #ffffff;
    box-shadow: 0px 5px 10px #00000033;
    border-radius: 30px;
    padding: 28px;
    margin-left: 40px;
    margin-right: 40px;
  }

  &.modal_guerin {
    .row_segment {
      align-items: center;
      justify-content: left;

      input[type='radio'] {
        border: 0px;
        width: 100%;
        height: 2em;
      }

      .segment_image {
        width: 30px;
        height: auto;
        margin-right: 5px;
      }

      .segment_text {
        font-size: 10px;
        margin-bottom: 0px;
      }
    }

    .row_car {
      margin-top: 40px;
      align-items: center;

      .car_card {
        background-color: rgba(109, 171, 36, 0.2);
        border-radius: 30px;
        height: 239px;
        padding: 15px;

        .car_image {
          width: 200px;
          height: auto;
          margin-bottom: 10px;
        }

        .car_name {
          margin-bottom: 0px;
          font-weight: bold;
        }

        .car_details {
          margin-bottom: 0px;
        }
      }
    }

    .row_configure {
      align-items: center;
      height: 40px;

      p {
        margin-bottom: 0px;
      }
    }

    .row_value {
      align-items: center;
      height: 40px;
      margin-left: 40px;
      margin-right: 40px;
      border-top: 1px solid rgba(255, 255, 255, 0.3);

      p {
        margin-bottom: 0px;
      }

      .value_label_text {
        color: #ffffff;
        font-size: 18px;
      }

      .value_text {
        text-align: right;
        font-size: 18px;
        font-weight: bold;
      }
    }

    .row_button {
      justify-content: center;
      margin-top: 10px;

      .button {
        width: 293px;
        height: 66px;
        background-color: #ffffff;
        border-radius: 33px;
        color: #000;
      }
    }
  }

  &.modal_xtracars {
    .row_segment {
      align-items: center;
      justify-content: left;

      input[type='radio'] {
        border: 0px;
        width: 100%;
        height: 2em;
      }

      .segment_image {
        width: 30px;
        height: auto;
        margin-right: 5px;
      }

      .segment_text {
        font-size: 10px;
        margin-bottom: 0px;
      }
    }

    .row_car {
      margin-top: 40px;
      align-items: center;

      .car_card {
        background-color: rgba(254, 193, 2, 0.2);
        border-radius: 30px;
        height: 239px;
        padding: 15px;

        .car_image {
          width: 200px;
          height: auto;
          margin-bottom: 10px;
        }

        .car_name {
          margin-bottom: 0px;
          font-weight: bold;
        }

        .car_details {
          margin-bottom: 0px;
        }
      }
    }

    .row_configure {
      align-items: center;
      height: 40px;

      p {
        margin-bottom: 0px;
      }
    }

    .row_value {
      align-items: center;
      height: 40px;
      margin-left: 40px;
      margin-right: 40px;
      border-top: 1px solid rgba(255, 255, 255, 0.3);

      p {
        margin-bottom: 0px;
      }

      .value_label_text {
        color: #ffffff;
        font-size: 18px;
      }

      .value_text {
        text-align: right;
        font-size: 18px;
        font-weight: bold;
      }
    }

    .row_button {
      justify-content: center;
      margin-top: 10px;

      .button {
        width: 293px;
        height: 66px;
        background-color: #ffffff;
        border-radius: 33px;
        color: #000;
      }
    }
  }

  &.modal_finlog {
    .row_car {
      align-items: center;

      .car_card {
        background-color: rgba(0, 161, 237, 0.2);
        border-radius: 30px;
        height: 239px;
        padding: 15px;

        .car_image {
          width: 200px;
          height: auto;
          margin-bottom: 10px;
        }

        .car_name {
          margin-bottom: 0px;
          font-weight: bold;
        }

        .car_details {
          margin-bottom: 0px;
        }
      }
    }

    .row_info {
      align-items: center;
      height: 40px;

      p {
        margin-bottom: 0px;

        &.value {
          font-weight: bold;
          text-align: right;
        }
      }
    }

    .row_value {
      align-items: center;
      height: 40px;
      margin-left: 40px;
      margin-right: 40px;
      border-top: 1px solid rgba(255, 255, 255, 0.3);

      p {
        margin-bottom: 0px;
      }

      .value_label_text {
        color: #ffffff;
        font-size: 18px;
      }

      .value_text {
        text-align: right;
        font-size: 18px;
        font-weight: bold;
      }
    }

    .row_extras {
      height: 120px;

      p {
        margin-bottom: 0px;

        &.extra_text {
          font-weight: bold;
        }
      }

      .extra_col {
        align-items: center;
      }
    }

    .row_button {
      justify-content: center;
      margin-top: 10px;

      .button {
        width: 293px;
        height: 66px;
        background-color: #ffffff;
        border-radius: 33px;
        color: #000;
      }
    }
  }

  &.modal_rodinhas {
    .row_car {
      align-items: center;
    }

    .custom-select {
      background-color: #ffcc00 !important;
    }

    .react-datepicker__input-container input {
      background-color: #ffcc00;
    }

    .form-control {
      background: #ffcc00 !important;

    }
    .row_button {
      justify-content: center;
      margin-top: 10px;

      .button {
        width: 293px;
        height: 66px;
        background-color: #ffffff;
        border-radius: 33px;
        color: #000;
      }
    }

    .title_col {
      align-items: center;

      .title {
        font-weight: bold;
      }
    }

    .add_text {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }

    .MuiInputBase-input {
      background-color: #ffcc00;
      display: block;
      width: 100%;
      height: 1.6rem;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      border-radius: 30px;
      border-bottom: 0px;
    }

    .MuiInput-underline:before {
      border-bottom: 0px;
    }

    .MuiInput-underline:after {
      border-bottom: 0px;
      transition: unset;
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
      color: #ffcc00;
    }

    .MuiRadio-colorSecondary.Mui-checked {
      color: #ffcc00;
    }
  }
}

.was-validated {
    .form-control:valid {
      padding-right: calc(1.5em + 0.75rem) !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
      background-repeat: no-repeat !important;
      background-position: right calc(0.375em + 0.1875rem) center !important;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
    }
  
  
    .form-control:invalid {
      padding-right: calc(1.5em + 0.75rem) !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
      background-repeat: no-repeat !important;
      background-position: right calc(0.375em + 0.1875rem) center !important;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
    }

    
    .form-control.is-valid {
      padding-right: calc(1.5em + 0.75rem) !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
      background-repeat: no-repeat !important;
      background-position: right calc(0.375em + 0.1875rem) center !important;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
    }
    .form-control.is-invalid{
      padding-right: calc(1.5em + 0.75rem) !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
      background-repeat: no-repeat !important;
      background-position: right calc(0.375em + 0.1875rem) center !important;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
    }
}