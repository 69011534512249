@import 'profile.scss';
input:focus,
textarea:focus,
select:focus,
div:focus {
  outline: none !important;
}

.react-datepicker-popper {
  z-index: 1000 !important;
}