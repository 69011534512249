.CMS_main {
  min-height: 50vh;
  padding-top: 65px;
  padding-bottom: 65px;
  padding-left: 80px !important;
  .marvel-device.iphone-x .screen {
    background: rgb(63, 165, 219);
    background: -moz-linear-gradient(
      180deg,
      rgba(63, 165, 219, 1) 0%,
      rgba(34, 113, 204, 1) 50%,
      rgba(34, 80, 204, 1) 100%
    );
    background: -webkit-linear-gradient(
      180deg,
      rgba(63, 165, 219, 1) 0%,
      rgba(34, 113, 204, 1) 50%,
      rgba(34, 80, 204, 1) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(63, 165, 219, 1) 0%,
      rgba(34, 113, 204, 1) 50%,
      rgba(34, 80, 204, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3fa5db",endColorstr="#2250cc",GradientType=1);
    align-content: center;
    text-align: center;
    padding-top: 60px;
    .onboarding_image {
      max-height: 400px;
      height: 80%;
      width: auto;
      margin-bottom: 80px;
    }
    h1 {
      color: #fff;
      font-size: 30px;
    }
    p {
      color: #fff;
      font-size: 20px;
    }
  }

  @media screen and (min-width: 768px) {
    .col-md-12{
      max-height: 600px !important;
    }
  }
}
