.input-price {
    width: 293px !important;
    height: 111px !important;
    margin-bottom: 20% !important;
    margin-top: 20% !important;
    text-align: center !important;
    font-size: 40px !important;
}

.button-save {
    text-align: center;
}

.margin-top {
    margin-bottom: 7% !important;
}