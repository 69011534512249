.cart_authorize {
  margin-bottom: 20px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;

  h1 {
    color: #2292cc;
    font-size: 26px;
    font-weight: bold;
  }
  .discount_card {
    padding: 10px 20px 10px 30px;
    background-color: #f7f5f5;
    border-radius: 20px;
    p {
      margin-bottom: 0px;
      text-align: center;
      .value {
        font-size: 20px;
        font-weight: bold;
        margin-left: 10px;
        &.discount {
          font-size: 30px;
        }
      }
    }
  }
  .products_card {
    padding: 10px 20px 10px 30px;
    margin-left: 10px;
    background-color: #f7f5f5;
    border-radius: 20px;
    p {
      margin-bottom: 0px;
      text-align: center;
    }
    .dashed {
      font-size: 20px;
      opacity: 0.5;
      color: #000000;
      text-decoration: line-through;
      text-decoration-style: dashed;
    }
  }

  .auth_row {
    box-shadow: 0px 2px 10px #1a84c64c;
    border-radius: 30px;
    min-height: 119px;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    h3 {
      color: #1e1e1e;
      font-size: 18px;
      font-weight: bold;
    }
    .circle {
      border-radius: 100%;
      border: 6px solid #1a84c6;
      height: 80px;
      width: 80px;

      h2 {
        color: #1e1e1e;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        margin-top: 26px;
      }
    }
    .MuiRadio-colorSecondary.Mui-checked {
      color: #1a84c6;
    }
    .MuiIconButton-colorSecondary {
      color: #8c9aa3;
    }
  }
  .button_row {
    justify-content: center;

    .button {
      width: 293px;
      height: 66px;
      background-color: #000000;
      border-radius: 33px;
    }
  }
}
