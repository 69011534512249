body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card-no-tabs {
  background-color: white;
  border: solid #ccc;
  border-width: 1px;
  height: 100%;
  border-radius: 25px;
}

p {
  font-size: 14px;
}

.hr-bold {
  border: 0.5px solid;
}

.bold {
  font-weight: bold;
}

.light {
  color: #878787;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.centerVertical {
  display: flex;
  align-items: center;
}

.bottom-divider {
  margin-bottom: 20px;
}

.division {
  margin-top: 20px;
}

.modal div {
  border-radius: 25px !important;
}

.bl-car {
  color: #2292cc;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltipText {
  visibility: hidden;
}

.tooltip:hover .tooltipText {
  visibility: visible;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: 9999; /*this will keep the icon appearing all time, even when on input::focus*/
}

.modal-image {
  width: 200px;
  height: 200px;
}