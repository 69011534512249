.movements_main {
    min-height: 80vh;
    padding-top: 65px;
    padding-bottom: 65px;
    padding-left: 80px !important;

    .marvel-device.iphone-x .screen {
        background: rgb(63, 165, 219);
        background: -moz-linear-gradient(180deg,
                rgba(63, 165, 219, 1) 0%,
                rgba(34, 113, 204, 1) 50%,
                rgba(34, 80, 204, 1) 100%);
        background: -webkit-linear-gradient(180deg,
                rgba(63, 165, 219, 1) 0%,
                rgba(34, 113, 204, 1) 50%,
                rgba(34, 80, 204, 1) 100%);
        background: linear-gradient(180deg,
                rgba(63, 165, 219, 1) 0%,
                rgba(34, 113, 204, 1) 50%,
                rgba(34, 80, 204, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3fa5db", endColorstr="#2250cc", GradientType=1);
        align-content: center;
        text-align: center;
        padding-top: 60px;

        .onboarding_image {
            max-height: 400px;
            height: 80%;
            width: auto;
            margin-bottom: 80px;
        }

        h1 {
            color: #fff;
            font-size: 30px;
        }

        p {
            color: #fff;
            font-size: 20px;
        }
    }

    @media screen and (max-width: 1190px) {
        min-height: 820px;
    }
}