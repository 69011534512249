@import 'dashboard.scss';
@import 'documents.scss';
@import 'help.scss';
@import 'invoices.scss';
@import 'reservation.scss';
@import 'subscriptions.scss';

.content_card {
  min-height: 500px;
  padding: 30px;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}
