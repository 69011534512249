.user__dashboard {
  min-height: 500px;

  .services_logo {
    width: 120px;
    height: auto;
  }

  .services_logo:hover {
    box-shadow: 0px 12px 20px #2292cc4c;
    border-radius: 38px;
    cursor: pointer;
  }

  .custom-select {
    background-color: #ffffff !important;
    box-shadow: 0px 0px 15px #00aeef26 !important;
    border: 2px solid #baddf0 !important;
    color: #baddf0 !important;
    font-weight: bold !important;
    text-align: center !important;
    background: #fff url('../../assets/icons/arrow-down.svg') right 0.75rem center/8px 10px no-repeat;
  }

  .service_label {
    width: 20px;
    height: 20px;
    border-radius: 50px;
  }

  .finlog {
    background-color: #00aeef;
  }

  .guerin {
    background-color: #8dc63f;
  }

  .rodinhas {
    background-color: #ffcc00;
  }

  .xtracars {
    background-color: #3b3b3b;
  }

  .bedriven {
    background-color: #1a84c6;
  }

  .chart_container {
    background: transparent linear-gradient(180deg, #ffffff 0%, #e8f7ff 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 12px 20px #2292cc4c;
    border-radius: 100%;
    margin: 20px;
    height: 230px;
    width: 230px;
    padding: 10px;

    canvas {
      height: 200px;
      width: 200px;
    }

    .month {
      position: absolute;
      top: 70px;
      color: #000;
    }

    .balance {
      position: absolute;
      top: 80px;
      font-size: 30px;
      text-align: center;
      color: #000;
    }

    .avaible {
      position: absolute;
      top: 155px;
      font-size: 14px;
      text-align: center;
      color: #000;
    }

    .avaible_label {
      position: absolute;
      top: 140px;
      font-size: 14px;
      text-align: center;
      color: #000;
    }

    .nodata {
      position: absolute;
      top: 25px;
      z-index: 20 !important;
    }

    .data {
      position: absolute;
      top: 25px;
      z-index: 100 !important;
    }
  }

  .chart {
    .month_button {
      background-color: #ffffff !important;
      border-radius: 20px;
      width: 80px;
      height: 45px;
      position: relative;
      border-color: #baddf0 !important;
      transition: none !important;
    }

    .btn {
      transition: none !important;
    }

    p {
      margin-top: 15px;
      text-align: center;
      color: #2292cc;
    }
  }

  .chart_labels {
    h3 {
      font-size: 14px;
      font-weight: bold;
      color: #000;
    }
  }

  .movements {
    padding: 30px;

    table {
      margin-top: 20px;

      h3 {
        font-size: 18px;
        font-weight: bold;
        font-family: 'Roboto';
        color: #000;
      }

      img {
        margin-right: 5px;
      }

      .logo_table {
        width: 80px;
        height: auto;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .dashboard-services {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;

      .service {
        padding: 0;
      }
    }

    .services-info {
      .chart-months{
        display: grid;
        gap: 40px;

        .col{
          padding-top: 0 !important;
          display: flex;
          align-items: center;
          gap: 16px;
          justify-content: center;

          &:last-of-type{
            padding-top: 40px !important;
          }
          p{
            margin: 0;
          }
        }
        div.chart_container {
          font-size: 12px !important;
          margin: 0px;
          height: 130px;
          width: 130px;
          padding: 0px;
          // margin: 20px;
          // padding: 10px;
  
          .month{
            top: 40px;
            font-size: 12px;
          }
  
          .balance{
            top: 55px;
            font-size: 16px;
          }
  
          .avaible_label{
  
          }
  
          .avaible{
  
          }
  
          canvas {
            top: -6px;
            // height: 100px;
            // width: 100px;
          }
        }
      }

      .chart_labels{
        .chart_labels-info{
         display: grid !important;
         justify-content: center;

         .row{
           justify-content: center;
           

         }
        }
      }
    }
  }
}