.comercial_view {
  padding-bottom: 50px;
  .edit_button {
    box-shadow: 0px 0px 5px #1a84c64c;
    border-radius: 25px;
    width: 83px;
    height: 32px;
    background-color: #ffffff;
    text-align: center;
    padding-top: 2px;
    margin-left: 10px;
    &:hover {
      background-color: #2292cc;
      .icon {
        color: #ffffff;
      }
    }
    .icon {
      width: 15px;
      height: 15px;
    }
  }

  .clientPageDivision {
    margin: '0 40px 0 40px';
  }

  .clientPageDivisionHeader {
    margin: '0 0 0 95.5px';
  }

  hr {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h4 {
    font-size: 18px;
  }
  h6 {
    font-size: 14px;
    font-weight: normal;
  }
}
.comercial_create {
  .upload {
    border-radius: 50px;
    width: 83px;
    height: 83px;
    text-align: center;
    padding-top: 27px;
    box-shadow: 0px 5px 10px #2292cc4c;
    cursor: pointer;
    position: relative;

    input {
      opacity: 0;
      height: 130%;
      width: 130%;
      top: 20;
      margin-top: -40px;
      left: 0;
      position: absolute;
      cursor: pointer;
    }
    #file-upload-button {
      cursor: pointer;
    }
  }

  #checkbox-male {
    z-index: 900 !important;
  }

  .react-datepicker-popper {
    z-index: 1000 !important;
  }

  .image {
    input {
      opacity: 0;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      cursor: pointer;
    }
  }

  .service_item {
    margin-top: 15px;
    .custom-checkbox .custom-control-label::before {
      border-radius: 1.25rem;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #2292cc;
      background-color: #2292cc;
    }
    .logo {
      height: 20px;
    }
  }
}
.comercial_list {
  .comercial_row {
    cursor: pointer;
    &:hover {
      background-color: #baddf0;
    }
  }
}
