div.groups_create {
  min-height: 500px;
  padding-left: 82.5px;
  padding-top: 51px;

  .description_header {
    margin-top: 10px;
    margin-right: 80px;
  }
  .container_group_card {
    margin-top: 60px;
    margin-right: 30px;
    .group_card {
      background: #1e1e1e 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 10px #2292cc4c;
      border-radius: 30px;
      text-align: center;
      padding: 25px 10px;
      min-width: 210px;
      margin: 20px 20px;
      .title {
        text-align: center;
        font: normal normal bold 18px/20px 'Roboto';
        letter-spacing: 0px;
        color: #00aeef;
        display: block;
      }

      .value {
        text-align: center;
        font: normal normal bold 40px 'Roboto';
        letter-spacing: 0px;
        color: #ffffff;
        display: block;
        margin-top: 10px;
      }

      .sub_value {
        text-align: center;
        font: normal normal 300 12px/16px 'Roboto';
        letter-spacing: 0px;
        color: #878787;
        display: block;
      }

      .services_bar {
        border-top: 1px solid rgba(255, 255, 255, 0.4);
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        margin-top: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        p {
          color: #ffffff;
          margin-bottom: 0px;
        }
        .icon-service-1 {
          color: #00aeef;
        }
        .icon-service-2 {
          color: #6dab24;
        }
        .icon-service-3 {
          color: #ffca00;
        }
        .icon-service-4 {
          color: #d8001a;
        }
        .icon-service-5 {
          color: #1a84c6;
        }
      }

      .users_label {
        text-align: center;
        font: normal normal normal 16px/21px 'Roboto';
        letter-spacing: 0px;
        color: #ffffff;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        display: block;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }

  @media screen and (max-width: 575px) {
    padding: 0;
  }
}

.groups_create_information {
  textarea:disabled {
    opacity: 0.7;
  }
  input:disabled {
    opacity: 0.7;
  }
  .users_title {
    color: #3b3b3b;
    text-align: left;
    font: normal normal bold 16px/21px 'Roboto';
    margin-left: 130px;
  }

  .users_subtitle {
    margin-left: 115px;
    font: normal normal 300 14px/19px 'Roboto';
    letter-spacing: 0px;
    color: #2292cc;
  }

  .selected_users {
    text-align: right;
    margin-right: 60px !important;
  }

  .users_card {
    border-bottom: 1px solid rgba(0, 0, 0, 0.221);
    h3 {
      font: normal normal bold 16px/21px 'Roboto';
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 0px;
    }
    p {
      margin-bottom: 10px;
      color: #878787;
      font: normal normal normal 12px 'Roboto';
    }
  }
}

.groups_create_logo_label{
  cursor: pointer;
  display: flex;
  align-items: center;
}

.groups_create_logo {
  width: auto;
  height: 20px;
}
