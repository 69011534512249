@font-face {
  font-family: 'fontello';
  src: url('./fontello.eot?36592656');
  src: url('./fontello.eot?36592656#iefix') format('embedded-opentype'),
    url('./fontello.woff2?36592656') format('woff2'),
    url('./fontello.woff?36592656') format('woff'),
    url('./fontello.ttf?36592656') format('truetype'),
    url('./fontello.svg?36592656#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?36592656#fontello') format('svg');
  }
}
*/
[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-service-5:before {
  content: '\e801';
} /* '' */
.icon-service-4:before {
  content: '\e800';
} /* '' */
.icon-service-3:before {
  content: '\e802';
} /* '' */
.icon-service-1:before {
  content: '\e803';
} /* '' */
.icon-service-2:before {
  content: '\e804';
} /* '' */
