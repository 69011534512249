div.servicesGM_main {
  padding-top: 65px;
  padding-bottom: 65px;
  padding-left: 80px;
  min-height: 500px;

  .title {
    text-align: left;
    font: normal normal bold 26px/34px 'Roboto';
    letter-spacing: 0px;
    color: #2292cc;
  }

  .subtitle {
    text-align: left;
    font: normal normal normal 16px/20px 'Roboto';
    letter-spacing: 0px;
    color: #000000;
  }

  .group_button_card {
    margin-top: 80px;
    margin-bottom: 80px;
    padding-left: 10px;
    gap: 40px;

    a {
      text-decoration: none;
    }

    .button_card {
      background: #2292cc 0% 0% no-repeat padding-box;
      box-shadow: 0px 5px 20px #2292cc99;
      border-radius: 25px;
      width: 232px;
      height: 78px;
      text-align: center;

      p {
        font: normal normal bold 16px/21px 'Roboto';
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
        margin-bottom: 0px;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .service_card {
    height: 191px;
    width: auto;
  }

  .description {
    text-align: left;
    font: normal normal 300 14px/20px 'Roboto';
    letter-spacing: 0px;
    color: #878787;
  }

  &.service_user {
    .input-search {
      width: 300px !important;
      margin-top: 50px;
    }

    table {
      margin-top: 50px;
      margin-bottom: 50px;

      th {
        font: normal normal bold 13px/18px 'Roboto';
        letter-spacing: 0px;
        color: #3b3b3b;
      }

      span {
        border-radius: 20px;
        padding: 5px 15px;
        font: normal normal bold 14px/19px 'Roboto';
        letter-spacing: 0px;
        color: #ffffff;
      }

      .name {
        font: normal normal bold 16px/21px 'Roboto';
        letter-spacing: 0px;
        color: #000000;
        margin-bottom: 0px;
      }

      .email {
        font: normal normal 300 14px/19px 'Roboto';
        letter-spacing: 0px;
        color: #878787;
        margin-bottom: 0px;
      }

      .icon-service-1 {
        color: #00aeef;
      }

      .icon-icon-service-2 {
        color: #6dab24;
      }

      .icon-service-5 {
        color: #1a84c6;
      }

      .icon-service-3 {
        color: #ffca00;
      }

      .icon-service-4 {
        color: #d8001a;
      }
    }
  }

  &.service_config {
    .plafond {
      margin-top: 50px;
    }

    .services {
      margin-top: 50px;
      margin-bottom: 80px;
    }
  }

  &.service_groups {
    .container_group_card {
      margin-top: 60px;
      margin-right: 30px;

      .group_card {
        background: #1e1e1e 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 10px #2292cc4c;
        border-radius: 30px;
        text-align: center;
        padding: 25px 10px;
        min-width: 210px;
        margin: 20px 20px;
        cursor: pointer;

        .title {
          text-align: center;
          font: normal normal bold 18px/20px 'Roboto';
          letter-spacing: 0px;
          color: #00aeef;
          display: block;
        }

        .value {
          text-align: center;
          font: normal normal bold 40px 'Roboto';
          letter-spacing: 0px;
          color: #ffffff;
          display: block;
          margin-top: 10px;
        }

        .sub_value {
          text-align: center;
          font: normal normal 300 12px/16px 'Roboto';
          letter-spacing: 0px;
          color: #878787;
          display: block;
        }

        .services_bar {
          border-top: 1px solid rgba(255, 255, 255, 0.4);
          border-bottom: 1px solid rgba(255, 255, 255, 0.4);
          margin-top: 20px;
          padding-top: 5px;
          padding-bottom: 5px;

          p {
            color: #ffffff;
            margin-bottom: 0px;
          }

          .icon-service-1 {
            color: #00aeef;
          }

          .icon-service-2 {
            color: #6dab24;
          }

          .icon-service-3 {
            color: #ffca00;
          }

          .icon-service-4 {
            color: #d8001a;
          }

          .icon-service-5 {
            color: #1a84c6;
          }
        }

        .users_label {
          text-align: center;
          font: normal normal normal 16px/21px 'Roboto';
          letter-spacing: 0px;
          color: #ffffff;
          margin-bottom: 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.4);
          display: block;
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  div.servicesGM_main {
    padding-left: 15px;

    .group_button_card {
      margin: 80px 0;
      padding-left: 0;
      a {
        flex-grow: 1;

        .button_card {
          width: unset;

          .row{
            margin: 0;
          }
        }
      }
    }

    .services{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  @media screen and (min-width: 576px) and ( max-width: 767px) {

  }
}