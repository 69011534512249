.configurator_configurator {
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
  padding-top: 60px !important;
  .title {
    font-weight: bold;
    margin-bottom: 0px;
  }
  .subtitle {
    color: #1e1e1e;
    margin-bottom: 0px;
  }
  .text_value {
    color: #2292cc;
    margin-bottom: 0px;
    margin-top: 4px;
    margin-left: 15px;
  }

  .segment_button {
    width: 105px !important;
    background-color: #fff !important;
    box-shadow: unset !important;
    border-color: unset !important;
    border-radius: 33px !important;
    &.selected {
      background-color: #2292cc !important;
      box-shadow: 0px 3px 6px #000d1433 !important;
      border-radius: 33px !important;
    }
  }

  .segment_text {
    text-align: center;
    color: #2292cc;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    &.selected {
      color: #fff;
    }
  }

  .segment_image {
    margin-left: auto;
    margin-right: auto;
    filter: invert(49%) sepia(12%) saturate(2629%) hue-rotate(156deg)
      brightness(100%) contrast(103%);
    &.selected {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(141deg)
        brightness(107%) contrast(101%);
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2293cc3a;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2292cc;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2292cc;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .MuiSlider-root {
    width: 82% !important;
    margin-left: 30px !important;
  }

  .custom-select {
    width: 30% !important;
    background-color: #2292cc26 !important;
    color: #2292cc;
  }

  .MuiSlider-thumb.Mui-disabled {
    width: 28px;
    height: 28px;
    margin-top: -14px;
    margin-left: -14px;
    background-color: #fff;
  }

  .simulate_button {
    background-color: #000 !important;
    width: 293px;
    height: 66px;
    box-shadow: 0px 5px 20px #1a84c64c;
    border-radius: 33px;
    border-color: unset !important;
  }
}
