.color-label {
 color: #2292CC !important;
}

.width-image {
  width: 210px;
  height: 61px;
}

.centralize {
  margin-left: 30%;
  margin-right: 40%;
}

.line {
  border-left: 1px solid #2292CC;
  height: 25px;
  margin: 0 10px 0 20px;
}

.general-card {
  margin: 20%, 2rem, 0, 3rem !important;
}

.text-center {
  text-align: center;
}

.label-top {
  margin-top: 10%;
  margin-bottom: 20%;
}

.margin-label {
  margin-right: 15px;
}

.sidebar {
 width: 20%;
}

