.configurator_packs {
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
  .container_cards {
    margin-top: 60px;
    .pack_card {
      box-shadow: 0px 10px 15px #00aeef26 !important;
      background-color: #f9fbff;
      width: 70vw;
      border-radius: 50px;
      margin-bottom: 60px;
      padding: 40px 40px;
      min-height: 20vh;

      h3 {
        text-align: center;
        color: #000;
        font-weight: bold;
      }
      h4 {
        text-align: center;
        color: #656565;
        font-size: 12px;

        padding-bottom: 20px;
      }
      .service_row {
        border-bottom: 1px #eeeeee solid;
        padding: 20px 30px;
        align-items: center !important;

        .service_logo {
          width: 80px;
          height: auto;
        }
        .product_name {
          text-transform: uppercase;
          color: #000;
          font-weight: bold;
          margin-bottom: 0px;
        }
        .product_description {
          margin-bottom: 0px;
        }
        .circle {
          width: 70px;
          height: 70px;
          border-radius: 100%;
          p {
            text-align: center;
            margin-top: 35%;
            font-weight: bold;
            font-size: 13px;
          }
        }
        .circle-service-1 {
          border: 5px solid #00aeef;
        }

        .circle-service-2 {
          border: 5px solid #6dab24;
        }

        .circle-service-5 {
          border: 5px solid #1a84c6;
        }

        .circle-service-3 {
          border: 5px solid #ffca00;
        }

        .circle-service-4 {
          border: 5px solid #d8001a;
        }
      }
      .divisor {
        border-bottom: 1px #eeeeee solid !important;
      }
      .edit_button {
        background-color: #1a84c6 !important;
        color: #fff;
        text-transform: uppercase;
        border-radius: 20px;
        width: 140px;
        text-align: center;
        padding: 5px;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 10px 15px #00aeef26 !important;
        }
      }
    }
  }
}
