.user_create {
  min-height: 500px;
  padding-left: 82.5px !important;
  padding-top: 51px;
  .header {
    margin-bottom: 44px;
    h2 {
      font-family: 'Roboto';
      font-weight: bold;
      font-size: 28px;
      color: #2292cc;
    }

    p {
      font-family: 'Roboto';
      font-weight: normal;
    }
    a {
      margin-left: 15px;
      margin-bottom: 20px;
      text-decoration: underline;
    }
  }
  h3 {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 18px !important;
    color: #2292cc;
  }

  .choose_box {
    background: #2292cc 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #2292cc99;
    border-radius: 40px;
    width: 173px;
    height: 166px;
    text-align: center;
    padding-top: 55px;
    margin-top: 50px;
    margin-bottom: 20px;
    cursor: pointer;
  }
}

.user_create--many {
  min-height: 500px;
  .file_name {
    background: #2292cc26 0% 0% no-repeat padding-box;
    height: 41px;
    width: 398px;
    padding: 5px 15px;
    p {
      text-decoration: underline;
      font: normal normal bold 16px/21px 'Roboto';
      letter-spacing: 0px;
      color: #2292cc;
      margin-bottom: 0px;
      margin-top: 4px;
    }
  }
  input {
    opacity: 0;
    height: 130%;
    width: 130%;
    top: 20;
    left: 0;
    position: absolute;
    cursor: pointer;
  }
}

.mobility__group--card {
  border-bottom: 1px solid rgba(0, 0, 0, 0.221);
  h3 {
    font: normal normal bold 16px/21px 'Roboto';
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 10px;
    color: #878787;
    font: normal normal normal 12px 'Roboto';
  }
}

.dowload_template {
  border-radius: 18px;
  height: 76px;
  text-align: center;
  border: 2px solid #baddf0;
  margin-top: -25px;
  cursor: pointer;
  img {
    display: block;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  a {
    text-align: center;
    font-size: 12px;
    color: #2292cc !important;
    cursor: pointer;
    &:hover {
      color: #fff !important;
    }
  }

  &:hover {
    border: 2px solid #2292cc;
    background-color: #2292cc;
    a {
      color: #fff !important;
    }
  }
}
