.user_cart {
  min-height: 500px;
  padding: 30px;
  padding-left: 80px !important;
  padding-right: 40px !important;

  h1 {
    color: #1e1e1e;
    font-size: 30px;
  }

  p {
    color: #1e1e1e;
  }

  .text_empty_cart {
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 60px;
  }

  .add_product {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #1a84c64c;
    border-radius: 25px;
    width: 83px;
    height: 78px;

    &:hover {
      background-color: #2292cc;
      color: #fff;
      opacity: 1;
    }
  }

  .pack_value {
    border-top: #2292cc4d 1px solid;
    padding-top: 20px;
    color: #1e1e1e;
    font-size: 18px;
  }

  .subscription_button {
    width: 293px;
    height: 66px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 33px;
  }

  .text_clean_cart {
    text-align: center;
    letter-spacing: 0px;
    color: #2292cc;
    text-decoration: underline;
    cursor: pointer;
  }
}

.product_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #1a84c64c;
  border-radius: 30px;
  padding: 30px 30px;
  margin-bottom: 40px;

  .service_logo {
    height: auto;
    width: 100px;
  }

  .actions_col {
    border-left: 1px solid #87878758;
    padding-left: 0px;
    padding-top: 15px;
  }

  .text_service {
    letter-spacing: -0.98px;
    text-align: left;
    color: #1e1e1e;
  }

  .text_product_name {
    color: #1e1e1e;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-weight: bold;
  }

  .circle {
    width: 70px;
    height: 70px;
    border-radius: 100%;

    p {
      text-align: center;
      margin-top: 35%;
      font-weight: bold;
      font-size: 13px;
    }
  }

  .circle-service-1 {
    border: 5px solid #00aeef;
  }

  .circle-service-2 {
    border: 5px solid #6dab24;
  }

  .circle-service-5 {
    border: 5px solid #1a84c6;
  }

  .circle-service-3 {
    border: 5px solid #ffca00;
  }

  .circle-service-4 {
    border: 5px solid #d8001a;
  }

  svg {
    cursor: pointer;
  }
}


@media screen and (max-width: 767px) {
  .user_cart {
    padding: 16px !important;
    .row{
      margin:0;
    }
    .subscription_button {
      width: 100%;
    }
  }
}