.user_documents {
  min-height: 500px;
  padding: 30px;
  padding-left: 80px !important;
  padding-right: 40px !important;
  padding-top: 100px !important;
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
  .title {
    color: #2292cc;
    font-size: 26px;
  }
  .subtitle {
    color: #1e1e1e;
  }

  .documents_container {
    margin-top: 60px;
    p {
      color: #1e1e1e;
    }
    .input_file {
      opacity: 0;
      height: 130%;
      width: 130%;
      top: 20;
      left: 0;
      position: absolute;
      cursor: pointer;
    }

    .document_row_preview {
      border: 1px solid #2292cc;
      border-radius: 30px;
      padding: 20px 10px;
      margin: 15px 0px 30px 0px;
      align-items: center;
      justify-content: center;
      .square_preview {
        width: 62px;
        height: 62px;
        border-radius: 20px;
        background-color: #2292cc;
        img {
          width: 62px;
          height: 62px;
          border-radius: 20px;
        }
      }
    }
  }
  .continue_button {
    width: 293px;
    height: 66px;
    background-color: #000000;
    border-radius: 33px;
    margin-top: 30px;
  }
}
