.dashboardGM {
  min-height: 500px;

  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 40px 0px;
  }

  .status_card {
    background: #2292cc 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #2292cc99;
    border-radius: 40px;
    text-align: center;
    padding: 30px 0;
    // margin-top: 40px;
    width: 278px;
    height: 220px;

    p {
      text-align: left;
      margin-left: 20px;
    }

    .title {
      font: normal normal bold 50px/66px 'Roboto';
      letter-spacing: 0px;
      color: #ffffff;
    }

    .sub_title {
      font: normal normal normal 16px/18px 'Roboto';
      letter-spacing: 0px;
      color: #ffffff;
    }
  }

  @media screen and (max-width: 575px) {
    .row {
      display: grid;
      div{
        padding: 0;
        .status_card {
          width: 100%;
          height: auto;
          padding: 30px;
        }
      }
    }
  }
}