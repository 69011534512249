.user__invoices {
  min-height: 500px;
  padding: 30px;
  padding-left: 80px !important;
  padding-right: 40px !important;

  .counter {
    color: #878787;
    font-size: 16px;
    line-height: 21px;
    font-family: 'Roboto';
  }
  .big_date {
    color: #2d2d2d;
    font-size: 22px;
    line-height: 29px;
    font-family: 'Roboto';
    font-weight: bold;
    margin-bottom: 0px;
  }
  .secondary_text {
    color: #878787;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Roboto';
    margin-bottom: 0px;
  }

  .amount {
    color: #1e1e1e;
    font-size: 16px;
    line-height: 21px;
    font-family: 'Roboto';
    font-weight: bold;
    margin-bottom: 0px;
  }
  button {
    background-color: #fff;
    border-color: unset;
    box-shadow: 0px 3px 15px #2292cc4c;
    border-radius: 15px;
    width: 83px;
  }
  .recent {
    width: 83px;
    height: 22px;
    background: transparent
      linear-gradient(180deg, #44a9de 0%, #2292cc 21%, #224fcc 100%) 0% 0%
      no-repeat padding-box;
    box-shadow: 0px 3px 6px #2292cc4c;
    border-radius: 15px;

    p {
      font-size: 12px;
      color: #fff;
      font-weight: normal;
      text-align: center;
    }
  }

  table {
    tr {
      border-top: unset !important;
    }
    td {
      border-bottom: 1px solid #dee2e6 !important;
      border-top: unset !important;
    }
    .no_border {
      border-bottom: unset !important;
      border-top: unset !important;
    }
    .center_vertically {
      vertical-align: middle;
    }
  }
}
