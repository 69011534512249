.container_modal_base {
  overflow: auto;

  .modal_base {
    width: 50% !important;
    border-radius: 30px;
    padding: 20px 40px 20px 40px !important;
    font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
    margin-top: 20px !important;

    .logo_service {
      height: 25px;
      width: auto;
    }

    .button_close {
      border-radius: 100% !important;
      border: none;
      background: none;
      outline: none;
      color: black;
      mix-blend-mode: screen;
      position: relative;
      height: 30px;
      width: 30px;
      opacity: 0.5;

      &:before {
        border-radius: 100% !important;
        background: white;
        border-radius: 0.3em;
        content: '';
        mix-blend-mode: color-burn;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    h3 {
      color: #fff;
      font-size: 20px;
      font-weight: normal;
    }

    .brand_col {
      // border-left: 1px solid #ffffff8a;
      align-items: center;
      justify-content: center;

      p {
        margin-bottom: 0px;
        color: #fff;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    .modal_base {
      width: 90% !important;

    }
  }
}