.users_table {
  .icon-service-1 {
    color: #00aeef;
  }

  .icon-icon-service-2 {
    color: #6dab24;
  }

  .icon-service-5 {
    color: #1a84c6;
  }

  .icon-service-3 {
    color: #ffca00;
  }

  .icon-service-4 {
    color: #d8001a;
  }
}
