.tabs {
  margin-top: 5%;
}

.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  border-top-left-radius: 20px;
  height: 60px;
  text-align: center;
  font-size: 22px;
  border-top-right-radius: 20px;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
  color: #2292cc;
  /*border-top-left-radius: 25px;
  border-top-right-radius: 25px;*/
}

.tab-border {
  border-top: none !important;
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  padding: 10px 40px 40px 40px !important;
}

.form-control {
  border-radius: 10rem !important;
  border: 0;
  background: #BADDF0 !important;
  color: #000 !important;
  outline: none;
  border: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

textarea.form-control {
  border-radius: 10px !important;
}

.custom-select {
  background-color: #BADDF0 !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 3.25rem !important;
}

.form-label {
  margin-top: 3%;
  color: #000;
  font-size: 14px;
}

/*.custom-control {
  border: 5px solid #e8f7ff;
  border-radius: 50%;
}*/

.card {
  background-color: white;
  border: solid #ccc;
  border-width: 0 1px 1px 1px;
  height: 100%;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

h3 {
  color: #2292cc;
  font-size: 26px;
  font-weight: bold;
}

h4 {
  color: #2292cc;
}

.center {
  align-items: center;
}

.cardPack {
  background-color: black !important;
  /*border: solid #ccc;*/
  border-width: 1px 1px 1px 1px;
  height: 100%;
  border-radius: 25px !important;
  color: white;
}

.divider {
  background-color: white;
}

.pack-img {
  display: inline-block;
  margin-right: 15px;
}

.image-size {
  margin: 5% 5% 5% 25%;
  width: 50%;
}

.form-button {
  margin-top: 5%;
  border-radius: 10rem !important;
  width: 40% !important;
}

.form-button-round {
  border-radius: 50% !important;
}

.wrapper-input {
  margin: 0 auto;
  display: flex;
}

.round {
  position: relative;
}

.wrapper-input i {
  color: #2292CC;
  font-size: 23px;
}

.round label {
  background-color: #2292CC4C;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}

.fix-checkbox {
  left: 8px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 8px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #2292CC;
  border-color: #2292CC;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.label-input {
  text-indent: 26px;
  position: relative;
  bottom: 3px;
  cursor: pointer;
}

.big-btn {
  width: 293px;
  height: 66px;
  box-shadow: 0px 7px 17px 4px rgba(209,230,243,0.65);
  border-radius: 40px;
  border: 0;
  background: #fff;
  color: #2292CC;
  font-weight: bold;
}

.small-btn {
  width: 80px;
  height: 66px;
  box-shadow: 0px 1px 15px 2px rgba(0,175,239,0.39);
  border-radius: 40px;
  border: 0;
  background: #fff;
  color: #2292CC;
  font-weight: bold;
}

.medium-btn {
  width: 200px;
  height: 40px;
  box-shadow: 0px 1px 15px 2px rgba(0,175,239,0.39);
  border-radius: 40px;
  border: 0;
  background: #fff;
  color: #C5C5C5;
  font-weight: bold;
}

.big-btn:hover {
  background: #2292CC;
  color: #fff;
}

.modal-content {
  border: 0 !important;
  padding: 100px;
  box-shadow: 0px 0px 5px 3px rgba(144,204,219,1);
}

.modal-content img {
  width: 400px !important;
  height: 300px !important;
}

.modal .modal-header {
  font-size: 20px;
  text-align: center;
  align-items: center !important;
  border: 0 !important;
  justify-content: center;
  font-weight: bold;
}

.modal .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
}

.modal .modal-footer {
  border: 0;
  justify-content: center;
}

.modal .modal-body svg {
  font-size: 40px;
}

.modal .modal-body .remove {
  color: #DF0000 !important;
}

.modal .modal-body .disclaimer {
  color: #878787;
}

.btn-remove {
  border: 2px solid #DF0000 !important;
  color: #DF0000 !important;
  border-radius: 100px !important;
  width: 180px !important;
  font-weight: bold !important;
}

.btn-back {
  border: 2px solid #2292CC !important;
  color: #2292CC !important;
  border-radius: 100px !important;
  width: 150px !important;
  font-weight: bold !important;
}

svg.remove:hover {
  color: #DF0000;
}

svg.edit:hover {
  color: #2292CC;
}

.react-datepicker__input-container input {
  border-radius: 20px;
  border: 0;
  padding: 5px;
  outline: none;
  background-color: #BADDF0;
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

.btn-link {
  outline: none !important;
  box-shadow: none !important;
  width: 100%;
  display: flex !important; 
  justify-content: flex-start !important;
  justify-content: space-between !important;
}


.navbar {
  width: 100% !important;
}

.custom-dialog .modal-header {
  align-items: left !important;
  justify-content: left !important;
}

.custom-dialog .modal-header h2 {
  color: #2292CC;
}

.custom-dialog .modal-header div {
  text-align: left;
}

.custom-dialog .modal-body .pack {
  background: #000 !important;
  position: relative;
  border-radius: 20px;
  box-shadow: 0 0 2px #000;
  min-height: 200px;
  text-align: center;
  max-height: 265px;
  width: 202px;
}

.custom-dialog .modal-body .pack .wrapper {
  color: #fff;
  padding: 50px 20px;
}

.custom-dialog .modal-body .pack .wrapper h5{
  color: #00AEEF
}

.custom-dialog .modal-body .pack .wrapper h4 {
  color: #fff
}

.custom-dialog .modal-body .pack .wrapper .big-text {
  font-weight: bold;
  font-size: 30px;
}

.custom-dialog .modal-body .pack .wrapper .social-networks {
  display: flex;
  justify-content: center;
  /* border-top: 1px solid gray; */
  /* border-bottom: 1px solid gray; */
}

.custom-dialog .modal-body .pack .wrapper .social-networks .icon-service-1 {
  color: #00AEEF;
}
.custom-dialog .modal-body .pack .wrapper .social-networks .icon-service-2 {
  color: #6DAB24;
}
.custom-dialog .modal-body .pack .wrapper .social-networks .icon-service-3 {
  color: #FFCA00;
}
.custom-dialog .modal-body .pack .wrapper .social-networks .icon-service-4 {
  color: #D8001A;
}
.custom-dialog .modal-body .pack .wrapper .social-networks .icon-service-5 {
  color: #1A84C6;
}

.opacity-0 {
  opacity:0!important;
}
.opacity-1 {
  opacity:0.2!important;
}
.opacity-2 {
  opacity:0.4!important;
}
.opacity-3 {
  opacity:0.6!important;
}
.opacity-4 {
  opacity:.8!important;
}
.opacity-5 {
  opacity:1!important;
}

/* Maybe even support hover opacity shifts */
.opacity-0h5 {
  opacity:0!important;
  transition: opacity .25s ease-in-out!important;
  -moz-transition: opacity .25s ease-in-out!important;
  -webkit-transition: opacity .25s ease-in-out!important;
}
.opacity-0h5:hover {
  opacity:1!important;
}

/* .pack {
  cursor: pointer;
  background: #000;
  position: relative;
  border-radius: 20px;
  box-shadow: 0 0 2px #000;
  min-height: 200px;
  text-align: center;
  max-height: 265px;
  width: 202px;

  .badge {
      
  }

  .wrapper {
      color: #fff;
      padding: 50px 20px;

      h5 {
          color: #00AEEF;
      }

      h4 {
          color: #fff;
      }

      .big-text {
          font-weight: bold;
          font-size: 30px;
      }

      .social-networks {
          display: flex;
          justify-content: center;
          border-top: 1px solid gray;
          border-bottom: 1px solid gray;

          .icon-service-1 {
              color: #00AEEF;
          }

          .icon-icon-service-2 {
              color: #6DAB24;
          }

          .icon-service-5 {
              color: #1A84C6;
          }

          .icon-service-3 {
              color: #FFCA00;
          }

          .icon-service-4 {
              color: #D8001A;
          }
      }
  }
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

input:disabled {
  -webkit-text-fill-color: gray !important; /* 1. sets text fill to current `color` for safari */
}

.Pickup-text{
  z-index: 25;
  position: relative;
}
.Dropoff-text{
  z-index: 21;
  position: relative;
}

.autocomplete-adresses{
  cursor: pointer;
  border-right: #ffcc00 2px solid;
  border-left:  #ffcc00 2px solid;
  border-bottom:  #ffcc00 2px solid;
  z-index: 20;
  background-color: white;
  position: absolute !important;
  right: 15px;
  left: 15px;
  margin-top: -15px;
  padding-top: 20px;

}

.autocomplete-adresses div:hover {
  background-color: #C5C5C5;
}