.reserve_card {
  width: 90%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #1a84c64c;
  border-radius: 30px;
  padding: 30px 30px;
  margin-bottom: 40px;

  .description {
    h4 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0px;

      span.bold {
        font-weight: 600;
      }
    }
  }

  .service_logo {
    height: auto;
    width: 100px;
  }

  .actions_col {
    border-left: 1px solid #87878758;
  }

  .text_service {
    letter-spacing: -0.98px;
    text-align: left;
    color: #1e1e1e;
  }

  .text_product_name {
    color: #1e1e1e;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-weight: bold;
  }

  .circle {
    width: 70px;
    height: 70px;
    border-radius: 100%;

    p {
      text-align: center;
      margin-top: 35%;
      font-weight: bold;
      font-size: 13px;
    }
  }

  .circle-service-1 {
    border: 5px solid #00aeef;
  }

  .circle-service-2 {
    border: 5px solid #6dab24;
  }

  .circle-service-5 {
    border: 5px solid #1a84c6;
  }

  .circle-service-3 {
    border: 5px solid #ffca00;
  }

  .circle-service-4 {
    border: 5px solid #d8001a;
  }

  svg {
    cursor: pointer;
  }

  .circle {
    width: 70px;
    height: 70px;
    border-radius: 100%;

    p {
      text-align: center;
      margin-top: 0px;
      font-weight: bold;
      font-size: 13px;
    }

    .badge_alert {
      height: 20px;
      width: 20px;
      border-radius: 50px;
      position: relative;
      top: 0px;
      left: 44px;
      background-color: #ffffff;

      svg {
        position: relative;
        top: -3px;
        right: -4px;
      }
    }
  }

  .status_card {
    padding: 10px;
    padding-left: 20px;
    background-color: #0000001A;
    margin-top: 20px;
    border-radius: 20px;

    p {
      margin-bottom: 0px;
    }

    .status_title {
      color: #2292cc;
      font-weight: 600;
      font-size: 10px;
      margin-bottom: 0px;
    }

    .status_text {
      font-size: 10px;
      margin-bottom: 0px;
      margin-left: 10px;
    }
  }
}

.reserve_modal {
  .card {
    background-color: #ffffff;
    box-shadow: 0px 5px 10px #00000033;
    border-radius: 30px;
    padding: 28px;
    margin-left: 40px;
    margin-right: 40px;

    p {
      color: #696969;
      margin-top: 10px;
      margin-bottom: 5px;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker__input-container input {
      border-radius: 20px;
      border: 1px solid rgb(103, 101, 101);
      padding: 5px;
      outline: none;
      background-color: #fff;
      padding-left: 10px;
      width: 100%;
    }

    .react-datepicker-popper {
      width: 350px !important;
    }

    .station_input {
      border-radius: 20px !important;
      border: 1px solid rgb(103, 101, 101);
      height: 36px;
      padding-left: 10px;
      width: 100%;
    }
  }

  @media only screen and (max-width: 1000px) {
    .card {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}

.vehicle_card {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #1a84c64c;
  border-radius: 30px;
  padding: 30px 30px;
  margin-bottom: 40px;
  cursor: pointer;

  &:hover {
    background: #eeeeee 0% 0% no-repeat padding-box;
    cursor: pointer;
  }

  .image {
    height: auto;
    width: 80px;
  }

  .name {
    font-weight: 600;
    margin-bottom: 0px !important;
  }

  .description {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }

  .value {
    font-weight: 600;
    text-align: center;
    font-size: 16px;

    .value_description {
      font-weight: 300;
      font-size: 12px;
    }
  }
}

.coverage_card {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #1a84c64c;
  border-radius: 30px;
  padding: 30px 30px;
  margin-bottom: 40px;
  cursor: pointer;

  &.selected {
    background: #eeeeee 0% 0% no-repeat padding-box;
    cursor: pointer;
  }

  h3.title {
    color: #000 !important;
    font-weight: 600 !important;
  }

  .value {
    font-size: 18px;
    font-weight: 600 !important;
  }

  .MuiAccordionSummary-root {
    min-height: unset !important;
    height: 34px !important;
  }
}

.extras_list {
  .excess {
    margin-top: -10px !important;
    padding-left: 2px;
  }

  .description_extra {
    margin-top: 0px !important;
    padding-left: 18px;
    margin-bottom: 25px !important;
    font-size: 10px;
  }

  .value {
    font-size: 18px;
    font-weight: 600 !important;
    text-align: right !important;
  }
}

.reserve_modal_rodinhas {
  input.form-control {
    background-color: unset !important;
    border: 1px solid black !important;
  }

  .card_itinerary {
    background: #eeeeee 0% 0% no-repeat padding-box;
    padding: 10px;
    border-radius: 20px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

.reserve_modal {
  .form-control {
    background-color: white !important;
    border: 1px solid #676565 !important;
  }
}

.footNote {
  font-size: 12px;
}

@media screen and (max-width: 575px) {
  div.reserve{
padding: 0;
    .reserve_card {
      padding: 16px;

      .status_card{
        margin-top: 16px;
      }
    }
  }
}