.rodinhas_screen {
  margin-left: 60px;
  margin-right: 60px;

  .logo {
    width: 246px;
  }

  .dowload_template {
    border-radius: 18px;
    height: 76px;
    text-align: center;
    border: 2px solid #baddf0;
    img {
      display: block;
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
    }

    a {
      text-align: center;
      font-size: 12px;
      color: #2292cc !important;
      cursor: pointer;
      &:hover {
        color: #fff !important;
      }
    }

    &:hover {
      border: 2px solid #2292cc;
      background-color: #2292cc;
      a {
        color: #fff !important;
      }
    }
  }
  .product_ready {
    .description {
      padding-top: 40px;

      p {
        font-size: 14px;
        font-family: 'Roboto';
        margin-right: 80px;
      }
    }
  }

  .upload_container {
    height: 320px;
    border: 2px dashed #baddf0;
    border-radius: 33px;
    position: relative;
    margin-top: 40px;
    margin-left: 0px;
    margin-right: 0px;
    input {
      opacity: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
    .upload_container__content {
      margin-left: auto;
      margin-right: auto;
      margin-top: 10%;
      text-align: center;
      a,
      p {
        font-family: 'Roboto';
        font-size: 13px;
      }
      p {
        margin-bottom: 0px;
        margin-top: 30px;
      }
      a {
        text-decoration: underline;
        color: #2292cc;
      }
    }
  }

  .uploaded_container_row {
    input {
      opacity: 0;
      width: 85%;
      height: 80px;
      position: absolute;
    }
    .uploaded_container {
      height: 52px;
      border: 2px solid #baddf0;
      border-radius: 13px;
      position: relative;
      margin-left: 10px;
      min-width: 272px;

      .uploaded_container__content {
        img {
          width: 42px;
          height: auto;
          margin-top: 15%;
        }
        p {
          margin-bottom: 0px;
          margin-top: 8%;
          vertical-align: middle;
          text-decoration: underline;
          font-family: 'Roboto';
          letter-spacing: 0px;
          font-size: 10px;
          color: #3b3b3b;
          position: absolute;
        }
      }
    }
  }
  .tabs {
    text-align: center;
    align-content: center;
    padding-left: 16px;
    padding-right: 6px;
    color: #fff;
    font-weight: bold;
    font-family: 'Roboto';

    .tab {
      background: #2292cc 0% 0% no-repeat padding-box;
      border-radius: 9px 9px 0px 0px;
      height: 38px;
      opacity: 0.2;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
      &--active {
        background: #2292cc 0% 0% no-repeat padding-box;
        opacity: 1;
        border-radius: 9px 9px 0px 0px;
        cursor: pointer;
      }
    }
    p {
      text-align: center;
      position: relative;
      top: 50%;
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  .table {
    table {
      width: 100%;
    }
    tr:not(:first-child) {
      td {
        padding: 0px;
        border-top-color: #fff;
        &.number {
          width: 20px;
          font-size: 12px;
          color: #c5c5c5;
          position: absolute;
        }
      }
    }

    tr:first-child {
      border-bottom: 1px solid #3b3b3b;
    }

    td {
      text-align: center;
      &.number {
        width: 20px;
        font-size: 12px;
        color: #c5c5c5;
        position: absolute;
      }

      &.fee {
        width: 120px;
      }

      & .cell {
        background: #baddf0;
        color: #2292cc;
        font-size: 14px;
        margin: 0px 0px 0px 20px;
      }
    }
  }

  .button {
    border: 1px solid #2292cc;
    border-radius: 33px;
    background-color: #fff;
    width: 293px;
    height: 66px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    p {
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0px;
      color: #2292cc;
      text-transform: uppercase;
      margin-top: 22px;
    }
    &:hover {
      background-color: #2292cc;
      p {
        color: #fff;
      }
    }
  }

  .modal-content {
    padding: 40px;
    h5,
    h3 {
      text-align: center;
    }
  }
}
